import 'babel-polyfill'
import './iframeResizer'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

// Utility functions

// https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
window.getMobileOperatingSystem = () => {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return 'Windows Phone'
	}

	if (/android/i.test(userAgent)) {
		return 'Android'
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return 'iOS'
	}

	return 'unknown'
}
